import api from "../api";
import Cookies from "js-cookie";


const auth_module = {
    state: {
        loginStatus: false,
        infoUser: null,
        tokenAuth: null,
    },
    mutations: {
        setLoginStatus(state, status) {
            state.loginStatus = status
        },
        setInfoUser(state, infoUser) {
            state.infoUser = infoUser
        },
        setTokenAuth(state, tokenAuth) {
            state.tokenAuth = tokenAuth
        },
    },
    actions: {
        setLogin(context, data) {
            context.commit("setLoginStatus", true);
            context.commit("setInfoUser", data.user_data);
            context.commit("setTokenAuth", data.access_token);
            api.authorization(true, data.access_token);
            Cookies.set("session_id", data.access_token);
            localStorage.setItem('infoUser', JSON.stringify(data.user_data))
        }
    },
    getters: {
        getLoginStatus(state) {
            return state.loginStatus
        },
        getInfoUser(state) {
            return state.infoUser
        },
        getTokenAuth(state) {
            return state.tokenAuth
        },
    },

};
export default auth_module
