<template>
  <v-app :id="isMobile ? 'fondo_mobil' : fond" > 
    <v-main :style="isMobile ? 'z-index: 24; margin-top:40px; background:#009ef7;' : 'z-index: 24; margin-top:110px;'">       
        <router-view />
    </v-main>
    <!-- <footer v-if="isMobile">
      <img src="bases/movil/guante.png" class="rounded float-right" alt="Image" style="margin-top:18px; z-index: -1; width: 80%;" >
    </footer> -->
  </v-app>
</template>

<script>
import $ from "jquery";
export default {
  name: 'App',
  data(){
    return{
      isMobile: this.$mobile(),
      ranking:false,
      solo:0,
      fondo:"fondo_pc",
      fond:'fondoblanc'
    }
  },
  created() {
    this.$bus.$on("cambia_fondo",
      () => {
        this.ranking = true;
      });

     this.$bus.$on("cambia_embajador",
      () => {
        this.fondo = "fondo_emb";
      });

    this.$bus.$on("cambia_fondo2",
      () => {
        this.ranking = false;
      });

   this.$bus.$on("cambi_fo",
      () => {
        $('#fondo_mobil').height(1650);
      });

   this.$bus.$on("cambi_fo2",
      () => {
        $('#fondo_mobil').height(1050);
      });

  this.$bus.$on("cambi_fo4",
      () => {
        $('#fondo_mobil').height(5400);
      });

 this.$bus.$on("cambi_fo5",
      () => {
        $('#fondo_mobil').height(3500);
      });

       this.$bus.$on("cambi_fo6",
      () => {
        $('#fondo_mobil').height(2500);
      });

      this.$bus.$on("cambi_fo7",
      () => {
        $('#fondo_mobil').height(1200);
      });

    this.$bus.$on("cambi_fo8",
      () => {
        $('#fondo_mobil').height(12000);
      });

      this.$bus.$on("cambi_fo9",
      () => {
        $('#fondo_mobil').height(2200);
      });

 this.$bus.$on("fondosolo",
      () => {
        this.solo = 1;
      });
  
  this.$bus.$on("fondosolo2",
      () => {
         this.solo = 0;
      });

     this.$bus.$on("fondo_capa",
      (data) => {
        this.fond = data; 
      });

        this.$bus.$on("ajusta_zoom",
      () => {
              $('#fondoblanc').height(1250); 
      });

  },
  mounted(){   
    // var height  = $(window).height();
    $('#fondo_mobil').height(900); 
    // $('#fondo_mobil2').height(height); 
    
  }
};
</script>
<style>
 #fondo_pc{
     background: url('https://prueba.convencioncomercial.com.mx/elementos/fondo_pc.jpg') no-repeat center center;
       -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-position: 0% 15%;
        background-size: cover;
        width: 100%!important;
 }

  #fondo_emb{
     background: url('https://prueba.convencioncomercial.com.mx/elementos/fondonuevo.jpg') no-repeat center center;
       -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-position: 0% 15%;
        background-size: cover;
        width: 100%!important;
 }


  #fondoSolo{
     background: url('https://prueba.convencioncomercial.com.mx/elementos/fondo_jon3.jpg') no-repeat center center;
       -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        /* background-position: 0% 15%; */
        background-size: cover;
        width: 100%!important;
 }

 
  #fondoCapa{
     background: url('https://prueba.convencioncomercial.com.mx/elementos/fondo_jon2.jpg') no-repeat center center;
       -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        /* background-position: 0% 15%; */
        background-size: cover;
        width: 100%!important;
 }

#fondoblanc{
     background: url('https://prueba.convencioncomercial.com.mx/elementos/fondo_jon.jpg') no-repeat center center;
       -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        /* background-position: 0% 15%; */
        background-size: cover;
        width: 100%!important;
 }

  #fondo_mobil{
     background:#009ef7;
       -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        /* background-position: 0% 15%; */
        background-size: cover;
        width: 100%!important;
    }

    #fondo_mobil2{
          background: url('https://prueba.convencioncomercial.com.mx/elementos/fondo_movil2.jpg') no-repeat center center;
       -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        /* background-position: 0% 15%; */
        background-size: cover;
        width: 100%!important;
    }
  /* #footer {
    width: 100%;
    height: 81px;
    position: absolute;
    bottom: 0;
    left: 0;
} */
      @font-face {
          font-family: 'TelefonicaLight';
          src: url("http://localhost:8080/fonts/Telefonica-Light.otf") format("opentype");
      }

      @font-face {
          font-family: 'TelefonicaRegular';
          src: url("http://localhost:8080/fonts/Telefonica-Regular.otf") format("opentype");
      }

</style>