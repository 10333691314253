import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import store from './store/index'
// import PageNotFound from './components/dashboard/NotFound'
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./components/dashboard/Home'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/bienvenida',
      name: 'bienvenida',
      component: () => import('./components/dashboard/Bienvenida'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      name:"login",
      component: () => import("@/components/login/Inicio")
    },
    {
      path: "/intro",
      name:"Intro",
      component: () => import("@/components/login/Intro")
    },
    {
      path: "/propio",
      name:"base-propio",
      component: () => import("@/components/dashboard/BasePropio"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/retail",
      name:"base-retail",
      component: () => import("@/components/dashboard/BaseRetail"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/mision",
      name:"mision",
      component: () => import("@/components/dashboard/Mision"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/tlmkt",
      name:"tlmkt",
      component: () => import("@/components/dashboard/BaseTlmkt"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/ranking",
      name:"ranking",
      component: () => import("@/components/Ranking/Ranking"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/ganadores",
      name:"ganadores",
      component: () => import("@/components/Ranking/Ganadores"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/embajador",
      name:"embajador",
      component: () => import("@/components/dashboard/Embajador"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/ranking-embajador",
      name:"RankingEmbajador",
      component: () => import("@/components/Ranking/Ranking2"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/piano/:folio",
      name:"Piano",
      component: () => import("@/components/dashboard/piano/juego4"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/encuesta-convencion",
      name:"EncuestaConvencion",
      component: () => import("@/components/dashboard/encuesta/Encuesta"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/encuesta-convencion-reporte",
      name:"EncuestaConvencionAdmin",
      component: () => import("@/components/dashboard/encuesta/EncuestaAdmin"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/valida",
      name:"valida",
      component: {
        render(c) {
          return c('router-view', {
            on: {
              showLoading: function (val) {
                this.$emit('intento_juego', val)
              }
            }
          })
        }
      },
      meta: {
        requiresAuth: false
      }
    },
    // {
    //   path: '',
    //   name: 'not_found',
    //   component: () => import('./components/dashboard/NotFound'),
    //   children: [
    //     {
    //       path: "*",
    //       component: PageNotFound
    //     }
    //   ],
    //   meta: {
    //     requiresAuth: false
    //   }
    // }
  ],
});

// import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  // if (to.name) {
    // Start the route progress bar.
    // NProgress.start(800);
  // }

  store.commit(
    "setRouterHistory",
    from
  );

if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (store.getters.getInfoUser && store.getters.getInfoUser.admin) {
      next();
      return;
    }
    next('/')
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.commit(
      "setRouteData",
      {requireAuth: true}
    );
    if (store.getters.getLoginStatus) {
      next();
      return
    }
    next('/login')
  } else {
    store.commit(
      "setRouteData",
      {requireAuth: false}
    );
    next()
  }

  // next();
});

// router.afterEach(() => {
//   // Complete the animation of the route progress bar.
//   NProgress.done();
// });

export default router;
