import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import api from "./api";
import store from "./store"
import router from "./router";
import detectMobile from "./utils/DetecMobile";
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueRouter from 'vue-router'
import InitController from "./utils/InitController"
import VueConfetti from 'vue-confetti'
import bus from "./utils/bus";
import ForceDownload from "./utils/ForceDownload";
import VueScrollTo from 'vue-scrollto';
// import metricas from "./metricas";

Vue.config.productionTip = false
Vue.prototype.$bus = bus;
Vue.prototype.$api = api;
Vue.prototype.$mobile = detectMobile;
Vue.prototype.$fileDownload = ForceDownload;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueConfetti)
Vue.use(VueRouter);
Vue.use(VueScrollTo);

//Definir inicio de sesion y otras cosas
InitController.start();

//Declarar metricas
// metricas(router);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
